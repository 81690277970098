module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"blog.jcronquist.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/codebuild/output/src229738552/src/blog-jcronquist/node_modules/gatsby-tinacms-git","id":"40429aae-f2b6-58c6-ba5c-5ed008ecab8d","name":"gatsby-tinacms-git","version":"0.31.0","pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateDevServer"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]},{"resolve":"/codebuild/output/src229738552/src/blog-jcronquist/node_modules/gatsby-tinacms-remark","id":"5df20784-2a6f-5d83-a3bb-e31e9e5a5d06","name":"gatsby-tinacms-remark","version":"0.31.0","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]}],"sidebar":{"enabled":false,"position":"displace","theme":{"color":{"primary":{"light":"#00AEC3","medium":"#00AEC3","dark":"#00AEC3"}}}}},
    },{
      plugin: require('../node_modules/gatsby-tinacms-git/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-tinacms-remark/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src229738552/src/blog-jcronquist/src/components/siteLayout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blog Jason Cronquist","short_name":"Blog Jason Cronquist","start_url":"/","background_color":"#00AEC3","theme_color":"#00AEC3","display":"minimal-ui","icon":"content/images/circuit.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e99b78ad7cc08cde659c36d3da873782"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":880,"withWebp":true,"showCaptions":true,"linkImagesToOriginal":true,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"Lato","file":"https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
